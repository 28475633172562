
import { UFliterals } from "@/utils/literals";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ActivityForm from "@/components/admin/entityForm/ActivityForm.vue";
import TipGroupForm from "@/components/admin/entityForm/TipGroupForm.vue";
import SequenceForm from "@/components/admin/entityForm/SequenceForm.vue";
import { IUser } from "@/schemas/IUser";
import { mapGetters, mapState } from "vuex";
import { duplicateSlideImages } from "@/utils";
import { ISetting } from "@/schemas/ISetting";
import { IItem } from "@/schemas/IItem";

@Component({
  components: { SequenceForm, TipGroupForm, ActivityForm },
  computed: {
    ...mapState("user", ["users"]),
    ...mapGetters("user", ["canDuplicate", "getUserId", "canCreate", "isAdministrator"]),
  },
})
export default class CrudTable extends Vue {
  @Prop({ required: true, type: Array })
  readonly headers!: Array<string>;
  @Prop({ type: Array, required: true })
  readonly data!: Array<Record<string, any>>;
  @Prop({ type: String, required: true })
  readonly tableTitle!: string;
  @Prop({ type: String, required: true })
  readonly storeModuleName!: string;
  @Prop({ type: Function, required: true })
  readonly updateHandler!: CallableFunction;
  @Prop({ type: Function, required: true })
  readonly createHandler!: CallableFunction;
  @Prop({ type: Function, required: true })
  readonly deleteHandler!: CallableFunction;

  private search = "";
  private expanded = [];
  private dialog = false;
  private dialogDelete = false;
  private dialogCancel = false;
  private editedIndex = -1;
  private editedItem: Record<string, any> = {};
  private dialogConsult = false;
  private dialogDuplicate = false;
  private canDuplicate: boolean | undefined;
  private canCreate: boolean | undefined;
  private users: IUser[] | undefined;
  private getUserId: string | undefined;
  private tab = null;
  private tabs = ["mine", "other"];

  data(): any {
    return {
      firstIteration: false,
      firstIterationM:false,
    }
  }


  get columns(): Array<Record<string, any>> {
    let columns: Record<string, any>[] = [];
    // delete this.headers[2] // TO DO => supprimer plutôt dans les headers
    if (this.storeModuleName== 'activity'){
      delete this.headers[1]
    }
    this.headers.map((header) => {
      if (
        header == "guideline" ||
        header == "id" ||
        header == "background_img" ||
        header == "authorized_instructors" ||
        header == "description"
      ) {
        return;
      }
      if (
        header == "single_session_time" ||
        header == "group_session_time"
      ) {
        columns.push({
          text: this.getUFHeader(header),
          align: "start",
          value: header,
          width: "100px",
          sortable: true,
        });
        return;
      }
      if (header == "author_id") {
        columns.push({
          text: this.getUFHeader(header),
          align: "start",
          value: "author_nickname",
          sortable: true,

        });
        return;
      }
      columns.push({
        text: this.getUFHeader(header),
        align: "start",
        value: header,
        sortable: true,
      });
    });
    if (this.data.length) {
      columns.push({
        text: "Actions",
        value: "actions",
        sortable: false,
        width: "170px",
      });
    }
    console.log(columns)
    return columns;
  }

  created(): void {

    this.firstIteration=false;
    this.firstIterationM=false;
  }


  get formTitle(): string {
    return this.editedIndex === -1
      ? `${this.getUFHeader("creation")} : ${this.tableTitle}`
      : `${this.getUFHeader("edition")} : ${this.tableTitle}`;
  }

  @Watch("dialog")
  dialogChanged(val: boolean): void {
    val || this.close();
  }

  @Watch("dialogDelete")
  dialogDeleteChanged(val: boolean): void {
    val || this.closeDelete();
  }

  redirectToSequence(sequenceId: string): void {
    this.$router.push({
      name: "sequence",
      params: {
        id: sequenceId,
      },
    });
  }


  /**
   * Retrieve user friendly header name
   * @param header
   */
  getUFHeader(header: string): string {
    return UFliterals[header];
  }

  editItem(item: Record<string, any>): void {
    this.editedIndex = this.data.indexOf(item);
    this.editedItem = Object.assign({}, item);
    let type = item.type ? item.type : "";
    this.$store.dispatch(`${this.storeModuleName}/fetchFullById`, {
      id: item.id,
      type,
      next: (item: Record<string, any>) => {
        this.editedItem = Object.assign({}, this.editedItem, item);
        this.$nextTick(function () {
          this.dialog = true;
        });
      },
    });
  }

  consultItem(item: Record<string, any>): void {
    this.editedIndex = this.data.indexOf(item);
    let type = item.type ? item.type : "";
    this.$store.dispatch(`${this.storeModuleName}/fetchFullById`, {
      id: item.id,
      type,
      next: (item: Record<string, any>) => {
        this.editedItem = Object.assign({}, item);
        this.dialogConsult = true;
      },
    });
  }

  deleteItem(item: Record<string, any>): void {
    this.editedIndex = this.data.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.dialogDelete = true;
  }

  duplicateItem(item: Record<string, any>): void {
    this.editedIndex = this.data.indexOf(item);
    let type = item.type ? item.type : "";
    this.$store.dispatch(`${this.storeModuleName}/fetchFullById`, {
      id: item.id,
      type,
      next: (fullItem: Record<string, any>) => {
        // When duplicate we don't want to keep the same id
        if (fullItem.id) delete fullItem.id;
        // If item is a sequence, we do not want to duplicate
        // code, is open, authorized instructors
        if (fullItem.code) delete fullItem.code;
        if (fullItem.is_open) fullItem.is_open = false;
        if (fullItem.authorized_instructors)
          delete fullItem.authorized_instructors;
        // Case if it's a sequence
        if (fullItem.settings) {
          fullItem.settings.map((setting: ISetting) => {
            delete setting.id;
            delete setting.sequence_id;
            delete setting.opened_at;
          });
        }
        // Case if it's an activity
        if (fullItem.items) {
          fullItem.items.map((item: IItem) => {
            delete item.id;
            delete item.activity_id;
          });
        }

        // Update the created at and author_id to the current author
        if (fullItem.created_at) delete fullItem.created_at;
        if (fullItem.author_id) delete fullItem.author_id;

        if (fullItem.slides) duplicateSlideImages(fullItem.slides);
        this.editedItem = Object.assign({}, fullItem);
        this.dialogDuplicate = true;
      },
    });
  }

  deleteItemConfirm(): void {
    this.deleteHandler(this.editedItem.id);
    this.closeDelete();
  }

  close(): void {
    this.dialog = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, {});
      this.editedIndex = -1;
    });
  }

  closeDelete(): void {
    this.dialogDelete = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, {});
      this.editedIndex = -1;
    });
  }

  cancelItem(): void {
    this.dialogCancel = true;
  }

  closeCancel(): void {
    this.dialogCancel = false;
    this.dialogDuplicate &&= false;
  }

  leaveConsult(): void {
    this.dialogConsult = false;
    this.close();
  }

  saveDuplicate(objectToSubmit: Record<string, any>): void {
    // TODO: if it is a sequence check that settings are duplicated but not sessions
    this.createHandler(objectToSubmit);
    this.dialogDuplicate = false;
    this.close();
  }

  cancelItemConfirm(): void {
    this.close();
    this.closeCancel();
  }

  save(objectToSubmit: Record<string, any>): void {
    if (this.editedIndex > -1) {
      this.updateHandler(objectToSubmit, this.editedItem.id);
    } else {
      this.createHandler(objectToSubmit);
    }
    this.close();
  }

  getExpanded(): string | null {
    return this.storeModuleName === "activity" ? "guideline" : "description";
  }

  getAuthor(id: string): string {
    let user = this.users?.find((user: IUser) => user.id === id);
    return user ? user.username : this.getUFHeader("undetermined");
  }

  isEnableToWrite(author_id: string): boolean {


    return (this.getUserId == author_id || this.isAdministrator);
  }

  getUFLabel(key: string): string {
    return UFliterals[key];
  }

  get filteredData(): Array<Record<string, any>> {
    let userId = this.getUserId;
    let data = [];
    if (this.tab === "tab-mine") {
      this.data.map((row) => {
        if (
          row.author_id === userId ||
          row.authorized_instructors?.find((auth) => auth === userId)
        ) {
          if(this.getAuthor(row.author_id) != "Non determiné"){
            row.author_nickname=this.getAuthor(row.author_id)
          }
          data.push(row);
          if(this.storeModuleName=="activity" && this.firstIterationM == false){

            data[data.length-1].discipline = this.getUFLabel(row.discipline)
          }

        }
      });
      this.firstIterationM = true;
      console.log(data)
      return data;
    }
    this.data.map((row) => {
      if (row.author_id != userId) {
        if(this.getAuthor(row.author_id) != "Non determiné"){
          row.author_nickname=this.getAuthor(row.author_id)
        }
        data.push(row);
        if(this.storeModuleName=="activity" && this.firstIteration == false){
          data[data.length-1].discipline = this.getUFLabel(row.discipline)
        }

      }
    });
    this.firstIteration = true;
    return data;
  }
}
